<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1>{{ translations.tcAddOrEditWitnessingEncounter }}</h1>
      <b-form>
        <section class="section-3 bg-block mb-4">
          <b-row>
            <b-col sm="6">
              <b-row>
                <b-col sm="12" class="font-style-3">
                  <b-form-group class="form-element">
                    <div class="form_label" for="name-input">{{ translations.tcName }}</div>
                    <b-form-input
                      :title="translations.tcName"
                      id="name-input"
                      :placeholder="translations.tcName"
                      class="g-select flex-0 mr-3 mb-3"
                      v-model="encounter.wit_name"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" class="font-style-3">
                  <b-form-group class="form-element">
                    <div class="form_label" for="content-input">{{ translations.tcContent }}</div>
                    <b-form-textarea
                      rows="5"
                      :title="translations.tcContent"
                      id="content-input"
                      :placeholder="translations.tcContent"
                      v-model="encounter.wit_content"
                      required
                    >
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-4">
          <div class="body">
            <div class="d-flex">
              <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
                >{{ translations.tcSave }}
              </b-button>
              <b-button variant="secondary" @click="handleDeleteClick" class="flex-0 mr-3 w-100-sd mb-sm-0">
                {{ translations.tcDelete }}
              </b-button>
              <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
                {{ translations.tcCancel }}
              </b-button>
            </div>
          </div>
        </section>
      </b-form>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-witnessing-encounter',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      encounter: {
        wit_name: '',
        wit_content: '',
        wit_key: null,
      },
      encObj: {
        wit_name: '',
        wit_content: '',
        wit_key: '',
        wit_delete_flag: false,
      },
    }
  },
  async created() {
    await Promise.all([this.getViewTranslations(), this.pageLoad()])
  },
  methods: {
    ...mapActions({
      addOrUpdateEncounter: 'prayerAndWitnessing/addOrUpdateEncounter',
      loadEncounterDetails: 'prayerAndWitnessing/loadEncounterDetails',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    validate() {
      this.errorMessages = []
      if (this.encounter.wit_name == '') {
        this.errorMessages.push(this.translations.tcErrorEncounterNameRequired)
      }
      if (this.encounter.wit_content == '') {
        this.errorMessages.push(this.translations.tcErrorEncounterContentRequired)
      }
      if (this.errorMessages.length <= 0) {
        return true
      }
      return false
    },
    async handleDeleteClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcEncounterWillBeDeletedOk,
        confirmButtonText: this.translations.tcOk || 'Ok',
        cancelButtonText: this.translations.tcCancel || 'Cancel',

      }).then((result) => {
        if (result.value) {
          this.encObj.wit_name = this.encounter.wit_name
          this.encObj.wit_content = this.encounter.wit_content
          this.encObj.wit_key = this.encounter.wit_key
          this.encObj.wit_delete_flag = true
          this.upsert(false)
        }
      })
    },
    async handleSaveClick() {
      if (!this.validate()) {
        this.$swal({
          icon: 'error',
          text: this.errorMessages.join(', '),
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
        return
      }
      this.encObj.wit_name = this.encounter.wit_name
      this.encObj.wit_content = this.encounter.wit_content
      this.encObj.wit_key = this.encounter.wit_key
      this.encObj.wit_delete_flag = false
      await this.upsert(true)
    },
    async upsert(isSave) {
      try {
        this.setLoadingStatus(true)
        let response = await this.addOrUpdateEncounter(this.encObj)
        this.setLoadingStatus(false)
        const textTrue = isSave ? this.translations.tcEncounterSubmitted : this.translations.tcEncounterDeleted
        const textFalse = isSave ? this.translations.tcErrorSubmitting : this.translations.tcErrorDeleting
        this.$swal({
          icon: response ? 'success' : 'error',
          text: response ? textTrue : `${textFalse} ${this.translations.tcTryAgain}`,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then(() => {
          if (response) {
            if (!!this.userSelectedEncounter.wit_name) {
              this.userSelectedEncounter.wit_name = this.encObj.wit_name
              this.userSelectedEncounter.wit_content = this.encObj.wit_content
            }
            this.handleCancelClick()
          }
        })
        return
      } catch (error) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          text: `${this.translations.tcErrorSubmitting} ${this.translations.tcTryAgain}`,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
    },
    async handleCancelClick() {
      this.$router.push({ name: 'personal-witnessing-encounters' })
    },
    async pageLoad() {
      if (!!this.userSelectedEncounter.wit_name) {
        this.encounter.wit_name = this.userSelectedEncounter.wit_name
        this.encounter.wit_content = this.userSelectedEncounter.wit_content
        this.encounter.wit_key = this.userSelectedEncounter.wit_key
      }
    },
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
      userSelectedEncounter: 'prayerAndWitnessing/userSelectedEncounter',
      userSelectedEncounterKey: 'prayerAndWitnessing/userSelectedEncounterKey',
    }),
  },
  components: {
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}
.form-group {
  margin-bottom: 0 !important;
}
.form_label {
  font-size: 14px !important;
  font-weight: 600;
}
.church_image {
  background-size: 100% 100%;
}
.remove_button {
  margin-top: 30px;
}
.check_label {
  position: relative;
  top: -40px;
  left: 30px;
  font-family: 'Open Sans';
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.btn.btn-outline,
.btn.btn-outline-primary {
  text-transform: uppercase;
  font-weight: 700;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;
  @include breakpoint(sm) {
    width: 24px;
  }
  input {
    order: 2;
  }
  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;
    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;
    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }
  .g-col-2 {
    max-width: 247px;
    width: 100%;
    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;
  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
